// Font Family
@use "sass:math";

$font-family-heading: 'neuzeit-grotesk', sans-serif;
$font-family-body: 'neuzeit-grotesk', sans-serif;

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color      : black;
$paragraph-size       : rem(20px);
$paragraph-line-height: 1.7em;
$paragraph-margin     : 20px;

// Header Styles
$header-color      : var(--main);
$header-size       : rem(60px);
$header-line-height: 1em;
$header-margin     : $paragraph-margin * 1.4;

// hr
$hr-margin: 50px;

// Type Misc
$font-weight-bold: 800;
$border-color: #D8D8D8 ;

*, html, body {
    font-size: $base-font-size;
    font-family: $font-family-body;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.noscroll {
        overflow-y: hidden;
        height: 100%;
    }
}

.wrapper {
    display: flex;
    flex-flow: column;
    min-height: 100vh;

    .footer {
        margin-top: auto;
    }
}

// Headers
h1, h2, h3, h4, h5, h6 {
    margin: $header-margin * 1.5 0 $header-margin 0;
    color: $header-color;
    font-family: $font-family-heading;
    line-height: $header-line-height;
    font-weight: 700;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: $header-size;
    margin: $header-margin * 1.5 0 $header-margin 0;

    @include media-breakpoint-down(md) {
        font-size: math.div($header-size, 1.3);
    }
}

h2 {
    font-size: math.div($header-size, 2);
    margin: ($header-margin * 1.5) 0 math.div($header-margin, 1.3) 0;
}

h3 {
    font-size: math.div($header-size, 2.2);
    margin: ($header-margin * 1.5) 0 math.div($header-margin, 1.5) 0;
}

h4 {
    font-size: math.div($header-size, 2.3);
    margin: ($header-margin * 1.5) 0 math.div($header-margin, 1.7) 0;
}

h5 {
    font-size: math.div($header-size, 2.4);
    margin: ($header-margin * 1.5) 0 math.div($header-margin, 1.8) 0;
}

// Paragraphs
p {
    margin-bottom: $paragraph-margin;
    font-size: $paragraph-size;
    line-height: $paragraph-line-height;
    color: $paragraph-color;

    &:last-of-type {
        margin-bottom: 0;
    }
}

// Links
a {
    color: black;
    text-decoration: underline;
}

// Lists
ul, ol {
    margin: $paragraph-margin 0 0 20px;

    li {
        font-size: $paragraph-size;
        margin-top: 10px;
        line-height: $paragraph-line-height;
        color: $paragraph-color;
    }

    ul, ol {
        margin-top: 0;
    }
}

ul {

    li {
        list-style: disc;
    }

    ul {

        li {
            list-style: circle;
        }
    }
}

ol {

    li {
        list-style: decimal;
    }
}

blockquote {
    padding: 50px 60px;
    background-color: var(--alt);
    margin: 30px 0 50px 0;
    text-align: center;

    * {
        color: var(--main);
    }

    @include media-breakpoint-down(md) {
        padding: 30px;
    }
}

// hr
hr {
    height: 1px;
    margin: $hr-margin 0;
    border: none;
    background-color: $border-color;
}

// Misc Styles
strong, b, .bold {
    font-weight: $font-weight-bold;
}

img {
    display: block;
    max-width: 100%;
}

::selection {
    color: white;
    background: black;
    text-shadow: none;
}

::-moz-selection {
    color: white;
    background: black;
    text-shadow: none;
}
