.block {
    display: block;
    position: relative;
    text-decoration: none;

    @include media-breakpoint-down(md) {
        margin: 0 0 30px 0;
    }

    .button {
        margin: 20px 0 0 0;
    }

    &.list {

        ul {
            margin: 30px 0 20px 0;

            li {
                display: inline-flex;
                align-items: center;
                position: relative;
                list-style: none;
                border-radius: 5px;
                padding: 10px 10px 10px 45px;
                margin: 0 20px 20px 0;
                font-size: 20px;
                color: var(--main);
                background-color: var(--alt);

                &:before {
                    content: url("data:image/svg+xml,%0A%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%23B61523' d='M12.5067624,0.104807056 L4.45264569,7.94912837 L1.49326822,5.06683741 C1.34978807,4.92709466 1.11714571,4.92709466 0.973634944,5.06683741 L0.107610108,5.91030384 C-0.0358700359,6.05004658 -0.0358700359,6.27662903 0.107610108,6.41640159 L4.19281375,10.3951929 C4.33629389,10.5349357 4.56893626,10.5349357 4.71244702,10.3951929 L13.8923899,1.45437124 C14.03587,1.3146285 14.03587,1.08804605 13.8923899,0.948273487 L13.0263651,0.104807056 C12.8828849,-0.0349356853 12.6502425,-0.0349356853 12.5067624,0.104807056 Z' id='Path'%3E%3C/path%3E%3C/svg%3E");
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 100%;
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }

    &.margin {
        margin: 0 0 30px 0;
    }
    
    &.intro {
        .block {

            &__text {
                color: var(--main);
                font-weight: 700;
            }
        }
    }

    &.center {
        text-align: center;
    }

    &.boxed {
        background-color: var(--main);
        border-radius: 5px;
        overflow: hidden;

        .block {

            &__img {
                min-height: 360px;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &__content {
                padding: 50px;

                @include media-breakpoint-down(md) {
                    padding: 30px;
                }
            }

            &__title, &__text {
                color: white;
                margin: 0;
            }

            &__title {
                margin: 0 0 10px 0;
            }
        }

        .button {
            margin: 30px 0 0 0;
        }

        &-bold {
            margin-top: -200px;

            .block {

                &__text {
                    font-weight: 700;
                    text-align: center;
                }
            }
        }

        ul {
            display: block;
            margin: 30px auto 0 auto;
            max-width: 600px;

            li {
                display: block;
                margin: 0 0 10px 0;
                color: white;
                border-radius: 5px;
                padding: 10px 20px;
                background-color: rgba(#000000, .1);

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &.text-white {

        .block {

            &__title, &__text {
                color: white;
            }
        }
    }

    &--boxed {
        display: grid;
        align-items: center;
        border-radius: 20px;
        background-color: var(--main);
        overflow: hidden;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 600px;
        }

        .block {

            &__text {
                color: white;
                font-weight: 700;
            }

            &__content {
                padding: 60px;
            }

            &__img {
                max-height: 250px;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &--usp {

        ul {
            margin: 0;

            li {
                display: flex;
                align-items: center;
                padding: 20px 20px 20px 70px;
                position: relative;
                margin: 0 0 20px 0;
                background-color: var(--alt);
                border-radius: 5px;

                &:before {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath fill='%23B61523' d='M30 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15Zm-16.735 7.942 11.129-11.129a.968.968 0 0 0 0-1.368l-1.369-1.369a.968.968 0 0 0-1.368 0l-9.076 9.076-4.238-4.237a.968.968 0 0 0-1.368 0l-1.369 1.368a.968.968 0 0 0 0 1.369l6.29 6.29a.968.968 0 0 0 1.369 0Z'/%3E%3C/svg%3E%0A");
                    position: absolute;
                    left: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    &--blocks {
        ul {
            margin: 0;

            li {
                display: block;
                list-style: none;
                padding: 50px;
                position: relative;
                margin: 0 0 30px 0;
                background-color: var(--alt);
                border-radius: 5px;

                @include media-breakpoint-down(md) {
                    padding: 30px;
                }
            }
        }
    }

    &--call {
        background-color: var(--main);
        border-radius: 5px;
        padding: 20px;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .button {
            margin: 0 15px;
        }

        .block {

            &__text {
                color: white;
                font-weight: 700;
            }
        }
    }

    &--partners {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
        }

        .block {

            &__item {
                padding: 20px;
            }

            &__img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    &--meet-us {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 0 50px 0;

        .block {

            &__title {
                display: block;
                width: 100%;
                color: white;
                margin: 0;
            }

            &__content {
                display: flex;
                position: relative;
                padding: 400px 30px 30px 30px;

                &.right {
                    .block {

                        &__title {
                            text-align: right;
                        }
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 64%, rgba(0,0,0,0.48) 100%);
                }
            }

            &__img {
                position: absolute;
                left: 0;
                top: 0;
                z-index: -2;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &__label {
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform: translate(-50%,-50%);
                line-height: 1;
                padding: 20px 30px;
                margin: 0;
                background-color: var(--main);
                color: white;
                border-radius: 5px;
                font-weight: 700;
            }
        }
    }

    &--person {
        border-radius: 5px;
        height: 500px;
        overflow: hidden;

        .block {

            &__img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &--services {

        ul {
            margin: 0;

            li {
                display: block;
                list-style: none;
                margin: 0 0 20px 0;

                a {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 1fr 20px;
                    color: white;
                    background-color: var(--main);
                    padding: 20px;
                    border-radius: 5px;
                    text-decoration: none;
                    font-weight: 700;
                    transition: .25s;

                    &:hover {
                        opacity: .8;
                    }

                    svg {
                        width: 15px;
                        height: 24px;
                    }
                }
            }
        }
    }

    &--consult {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;

        .block {

            &__title {
                font-size: 20px;
                margin: 0 0 10px 0;
            }

            &__text {
                font-size: 16px;
                opacity: .8;
            }

            &__price {  
                display: block;
                text-align: right;
                margin: auto;
                font-family: "neuzeit-grotesk-extra-conden";
                font-weight: 900;
                font-size: 50px;
                line-height: 50px;

                span {
                    font-size: 40px;
                    line-height: 40px;
                }
            }

            &__content {
                display: grid;
                grid-gap: 30px;
                grid-template-columns: 3fr 1fr;
                padding: 20px;
            }

            &__footer {
                padding: 15px 40px;
                background-color: var(--main);

                .block {

                    &__text {
                        text-align: center;
                        color: white;
                        opacity: 1;

                        a {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
