.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    background-color: black;
    border-radius: 10px;
    font-weight: 700;
    color: white;
    line-height: 1;
    text-decoration: none;

    &.icon {

        svg {
            height: 20px;
            width: 20px;
            margin: 0 15px 0 0;
        }
    }
}