.header {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 15px;
    @include sticky();
    width: 100%;
    height: 100px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;

    &__logo {
        height: 60px;
        margin-right: auto;
        transition: .25s;

        svg {
            height: 100%;
            width: 100%;
        }

        &:hover {
            opacity: .6;
        }
    }

    &__menu {

        ul {
            margin: 0;

            li {
                display: inline-block;
                margin: 0;

                a {
                    display: block;
                    text-decoration: none;
                    margin: 0 0 0 30px;
                    font-weight: 700;
                }

                &.active {
                    a {
                        color: var(--main);
                    }
                }
            }
        }
    }

    &__toggle {
        display: none;
        align-items: center;
        justify-content: flex-end;
        width: 25px;
        height: 20px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: var(--main);
            border-radius: 5px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
        }

        span:nth-child(1) {
            top: 0px;
        }

        span:nth-child(2) {
            top: 10px;
        }

        span:nth-child(3) {
            top: 20px;
        }

        &--open {

            span:nth-child(1) {
                top: 10px;
                transform: rotate(135deg);
            }

            span:nth-child(2) {
                opacity: 0;
                right: -60px;
            }

            span:nth-child(3) {
                top: 10px;
                transform: rotate(-135deg);
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 75px;
        transition: .25s;

        .header {

            &__logo {
                height: 40px;
            }

            &__menu {
                position: absolute;
                top: 75px;
                left: -100vw;
                visibility: hidden;
                width: 80vw;
                height: calc(100vh - 75px);
                background-color: var(--alt);
                transition: .25s;

                ul {
                    padding: 40px 30px;

                    li {
                        display: block;

                        a {
                            display: block;
                            font-size: 24px;
                            margin: 0 0 20px 0;
                        }

                        &:last-child { 

                            a {
                                text-align: center;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                background-color: var(--main);
                                padding: 15px;
                                color: white;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &__toggle {
                display: flex;
            }
        }
    }

    &--mobile {
        box-shadow: 0 0 0 999em rgba(0, 0, 0, 0.4);

        .header {

            &__menu {
                left: 0;
                visibility: visible;
            }
        }
    }
}
