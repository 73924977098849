.section {
    position: relative;
    padding: 100px 0;

    @include media-breakpoint-down(md) {
        padding: 75px 0 45px 0;
    }

    &__button {
        margin: 40px 0 0 0;
        text-align: center;
    }

    &--main {
        background-color: var(--main);
    }

    &--brandbox {

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 55vw;
            height: 100%;
            border-radius: 0 0 250px 0;
            background-color: var(--main);
        }

        .section {

            &__img {
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
                width: 70vw;
                height: 100%;
                object-fit: cover;
            }
        }

        @include media-breakpoint-down(md) {
            margin-top: 300px;
            padding: 75px 0 45px 0;

            &:before {
                width: 100%;
                border-radius: 0 150px 0 0;
            }

            .section {

                &__img {
                    top: -300px;
                    width: 100%;
                }
            }
        }
    }

    &--featured {
        padding: 200px 0;
        
        .section {

            &__img {
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &--image {
        padding: 0;
        
        .section {

            &__img {
                max-height: 500px;
                width: 100%;
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }
        }

        .block--call {
            margin-top: -50px;
        }
    }

    &--alt {
        background-color: var(--alt);
    }

    &--half {
        
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -3;
            width: 100%;
            height: 50%;
            background-color: var(--alt);
        }
    }
}
