.footer {

    &__title {
        color: white;
        margin: 0 0 10px 0;
        font-size: 20px;
    }

    &__text {
        font-size: 16px;
        color: white;
    }

    &__menu {
        margin: 0 0 30px 0;

        ul {
            margin: 0;

            li {
                margin: 0;
                list-style: none;

                a {
                    display: block;
                    color: white;
                    margin: 0 0 10px 0;
                    text-decoration: none;
                }
            }
        }
    }

    &__content {
        padding: 75px 0;
        background-color: var(--main);

        @include media-breakpoint-down(md) {
            padding: 75px 0 45px 0;
        }

        .block {

            &__title, &__text {
                color: white;
                margin: 0;

                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }

    &__logo {
        display: block;
        margin: 30px 0 0 0;

        svg {
            height: 50px;
            width: 100px;
        }

        @include media-breakpoint-down(md) {
            margin: 30px 0;
        }
    }

    &__meta {
        padding: 10px 15px;
        background-color: #92111C;

        ul {
            display: flex;
            justify-content: flex-end;
            margin: 0;

            li {
                list-style: none;
                margin: 0;

                a {
                    display: block;
                    margin: 0;
                    text-decoration: none;
                    color: white;
                    font-size: 16px;
                    opacity: .5;
                    transition: .25s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}