@use "sass:math";

@mixin sticky() {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
}

@mixin vertical-center {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return math.div($px, $base) * 1rem;
}

@mixin stickty() {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
}

// Event wrapper
// @param [bool] $self (false) - Whether or not to include current selector
// @author Harry Roberts
// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@function aspect($width) {
    @return math.div($width * 9, 16);
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
